import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination, Parallax } from "swiper/modules";

const reviewSwiper = new Swiper(".review-swiper", {
    slidesPerView: 1,
    autoplay: {
        delay: 10000,
    },
    breakpoints: {},
    parallax: true,
    modules: [Navigation, Autoplay, Pagination, Parallax],
    navigation: {
        prevEl: ".review-swiper-button-left",
        nextEl: ".review-swiper-button-right",
    },
});

const reviewLinks = document.querySelectorAll(".review-link");
const reviewSwiperSlides = [
    ...document.querySelectorAll(".review-swiper .swiper-slide"),
];

reviewLinks.forEach((reviewLink) => {
    reviewLink.addEventListener("click", () => {
        const reviewSlideID = reviewLink.dataset.targetId;
        reviewSwiper.slideTo(
            reviewSwiperSlides.findIndex((el) => {
                return el.dataset.id == reviewSlideID;
            })
        );
    });
});
